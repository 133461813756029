<template>
	<div>
		<CRow>
			<CCol md="9">
				<p class="typo-helper-text color-black-45 mb-0">
					Desktop banner will be displayed on the top of category page. Recommended cover image size is 1400x700px. <br>
					Mobile Banner will be displayed on the top of category page. Recommended cover image size is 600x500px.
				</p>
			</CCol>
			<CCol v-if="isShowAddBannerBtn" md="3" class="text-right">
				<CButton
					:disabled="isMaxValue"
					class="transparent"
					color="secondary"
					data-test-id="add-video"
					@click="handleAddItem"
				>
					<CIcon class="mr-2" name="cil-plus" /> Add Banner
				</CButton>
			</CCol>
		</CRow>

		<CRow>
			<CCol>
				<div v-for="(banner, index) in localBanners" :key="banner.key" class="row banner-item">
					<!-- desktop banner -->
					<div class="col-md-6">
						<BannerInputMedia
							ref="bannerInputMedia"
							:file="banner.desktopFile"
							:index="index"
							:title="`#Banner Desktop TH *`"
							data-test-id="category-desktop-banner-item"
							:is-show-delete-button="false"
							:is-show-link-input="false"
							@onInputImage="handleDesktopInputImage($event, index, 'desktopFile')"
						/>
					</div>
					<div class="col-md-6">
						<BannerInputMedia
							ref="bannerInputMedia"
							:file="banner.desktopENFile"
							:index="index"
							:title="`#Banner Desktop EN *`"
							data-test-id="category-desktop-en-banner-item"
							:is-show-delete-button="false"
							:is-show-link-input="false"
							@onInputImage="handleDesktopInputImage($event, index, 'desktopENFile')"
						/>
					</div>
					<!-- mobile banner -->
					<div class="col-md-6">
						<BannerInputMedia
							ref="bannerInputMedia"
							:file="banner.mobileFile"
							:index="index"
							:title="`#Banner Mobile TH *`"
							data-test-id="category-mobile-banner-item"
							:is-show-delete-button="false"
							:is-show-link-input="false"
							@onInputImage="handleMobileInputImage($event, index, 'mobileFile')"
						/>
					</div>
					<div class="col-md-6">
						<BannerInputMedia
							ref="bannerInputMedia"
							:file="banner.mobileENFile"
							:index="index"
							:title="`#Banner Mobile EN *`"
							data-test-id="category-mobile-en-banner-item"
							placeholder="add mobile banner link"
							:is-show-delete-button="false"
							:is-show-link-input="false"
							@onInputImage="handleMobileInputImage($event, index, 'mobileENFile')"
						/>
					</div>
					<div class="col-12">
						<BannerLink
							label="Link"
							placeholder="add banner link"
							:link="banner.link"
							:is-show-delete-button="isShowDeleteLink"
							@onChange="handleLinkChange($event, index)"
							@onDelete="handleDeleteItem(index)"
						/>
					</div>
				</div>
			</CCol>
		</CRow>
	</div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import BannerInputMedia from '@/components/BannerInputMedia.vue';
import BannerLink from '@/components/BannerLink.vue';

export default {
	name: 'CategoryBanners',

	components: {
		BannerInputMedia,
		BannerLink,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		isShowAddBannerBtn: {
			type: Boolean,
			default: true,
		},
		isShowDeleteLink: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			localBanners: [],
			maxValue: 10,
		};
	},

	computed: {
		isMaxValue() {
			return this.localBanners.length >= this.maxValue;
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(data) {
				// Watch props data change and update this
				this.localBanners = data?.map((banner) => {
					return {
						...banner,
						key: this.generateKey(),
					};
				}) ?? [];
			},
		},
	},

	methods: {
		generateKey() {
			return uniqueId('media_key_');
		},
		handleAddItem() {
			if (!this.isMaxValue) {
				this.localBanners = [
					...this.localBanners,
					{
						desktopFile: null,
						desktopENFile: null,
						mobileFile: null,
						mobileENFile: null,
						link: null,
						key: this.generateKey(),
					},
				];

				this.emitInputValue();
			}
		},

		handleDesktopInputImage(desktopFile, index, key) {
			this.localBanners[index][key] = desktopFile;
			// this.$set(this.localBanners, index, {
			// 	...this.localBanners[index],
			// 	desktopFile,
			// });

			this.emitInputValue();
		},

		handleMobileInputImage(mobileFile, index, key) {
			this.localBanners[index][key] = mobileFile;
			// this.$set(this.localBanners, index, {
			// 	...this.localBanners[index],
			// 	mobileFile,
			// });

			this.emitInputValue();
		},

		handleDeleteItem(value) {
			this.localBanners = this.localBanners.filter((_, index) => index !== value);

			this.emitInputValue();
		},

		handleLinkChange(link, index) {
			this.$set(this.localBanners, index, {
				...this.localBanners[index],
				link,
			});

			this.emitInputValue();
		},

		emitInputValue() {
			this.$emit('input', this.localBanners);
		},
	},
};
</script>

<style lang="scss" scoped>
.banner-item {
	margin-top: 16px;
}
</style>

