<template>
	<div>
		<div>
			<CInput
				v-model.trim="productFamiliesGroup"
				placeholder="Search by product families group"
				class="flex-fill"
				autocomplete="off"
				data-test-id="input-product-families-group"
			>
				<template #prepend-content>
					<CIcon class="cil-magnifying-glass" name="cil-magnifying-glass" />
				</template>
			</CInput>
		</div>
		<slot
			v-if="!filteredList.length"
			name="no-item"
		>
		</slot>
		<div v-show="filteredList.length" class="list">
			<DynamicScroller
				:items="filteredList"
				:min-item-size="35"
				key-field="id"
				class="scroller list-select"
			>
				<template v-slot="{ item, index, active }">
					<DynamicScrollerItem
						:item="item"
						:active="active"
						:data-index="index"
						class="list-item"
					>
						<div class="d-flex flex-fill">
							<CInputCheckbox
								:id="`${uid}_${item.id}`"
								:checked="checkItemIsSelected(item.id)"
								:disabled="selectedList.length > 0 && !checkItemIsSelected(item.id)"
								custom
								class="list-checkbox overflow-hidden"
								@update:checked="handleCheckedProductFamiliesGroup($event, item)"
							>
								<template #label>
									<label :for="`${uid}_${item.id}`" class="custom-control-label list-label w-100 typo-body-2 font-weight-normal text-nowrap text-truncate color-black-45 mb-0 ml-2">
										<span class="list-product-families-group color-black">{{ item.name }}</span>
									</label>
								</template>
							</CInputCheckbox>
						</div>
					</DynamicScrollerItem>
				</template>
			</DynamicScroller>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import uniqueId from 'lodash/uniqueId';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
	name: 'FormGetProductFamiliesGroup',
	components: {
		DynamicScroller,
		DynamicScrollerItem,
	},
	props: {
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			uid: null,
			productFamiliesGroup: '',
			productFamiliesGroupList: [],
		};
	},
	computed: {
		...mapGetters({
			getProductFamilyList: 'productFamilies/productFamiliesList',
			getProductFamiliesListMeta: 'productFamilies/productFamiliesListMeta',
		}),
		filteredList() {
			if (!this.productFamiliesGroup) {
				return this.getProductFamilyList;
			}

			return this.getProductFamilyList.filter((group) => group.name.toLowerCase().startsWith(this.productFamiliesGroup.toLowerCase()));
		},
	},
	watch: {
		filteredList() {
			this.checkSelectionState();
		},

		selectedList() {
			this.checkSelectionState();
		},
	},
	async created() {
		// // If no group or list get by paginate, fetch a new one.
		if (!this.getProductFamilyList.length || this.getProductFamiliesListMeta.from) {
			await this.getProductFamilies({ show_product: 0, per_page: 'all' });
		}
		this.productFamiliesGroupList = this.getProductFamilyList;
	},
	mounted() {
		this.uid = uniqueId('product_families_group_checkbox_');
	},
	methods: {
		...mapActions({
			getProductFamilies: 'productFamilies/getProductFamilies',
		}),
		resetState() {
			this.productFamiliesGroup = '';
		},
		checkItemIsSelected(val) {
			return this.selectedList.some((item) => item.id === val);
		},
		checkSelectionState() {
			const isEverySelected = this.filteredList.length && this.filteredList.every((group) => this.selectedList.map((item) => item.id).includes(group.id));
			const isSomeSelected = this.filteredList.length && this.filteredList.some((group) => this.selectedList.map((item) => item.id).includes(group.id));

			if (isEverySelected) {
				this.setSelectedAllState('checked');
				return;
			}

			if (isSomeSelected) {
				this.setSelectedAllState('indeterminated');
				return;
			}

			this.setSelectedAllState('unchecked');
		},
		setSelectedAllState(state) {
			let isChecked = false;
			let isIndeterminate = false;
			switch (state) {
				case 'checked': {
					isChecked = true;
					isIndeterminate = false;
					break;
				}
				case 'unchecked': {
					isChecked = false;
					isIndeterminate = false;
					break;
				}
				case 'indeterminated': {
					isChecked = true;
					isIndeterminate = true;
					break;
				}
				default: break;
			}

			const selectAllEle = this.$el.querySelector('.select-all-input input');
			if (selectAllEle) {
				selectAllEle.checked = isChecked;
				selectAllEle.indeterminate = isIndeterminate;
			}
		},
		handleCheckedProductFamiliesGroup(checked, group) {
			const isSelected = this.selectedList.some((item) => item.id === group.id);
			if (!isSelected) {
				this.addToSelectedList(group);
			} else {
				this.removeFromSelectedList(group.id);
			}
		},

		/**
		 * @param {String|Array} items Group or List
		 */
		addToSelectedList(items = []) {
			let newItems = items;
			if (!Array.isArray(items)) {
				newItems = [items];
			}
			const uniqueItems = [...new Set([...this.selectedList, ...newItems])].sort();
			this.$emit('onChange', uniqueItems);
		},

		/**
		 * @param {String|Array} items Group or List
		 */
		removeFromSelectedList(items = []) {
			let itemsToRemove = items;
			if (!Array.isArray(items)) {
				itemsToRemove = [items];
			}

			this.$emit('onChange', this.selectedList.filter((selectedItem) => !itemsToRemove.includes(selectedItem.id)));
		},
	},
};
</script>

<style lang="scss" scoped>
	.scroller {
		height: 100%;
	}

	.btn-add {
		min-width: rem(84);
	}

	.list {
		margin: 0 -#{rem(16)} -#{rem(16)};
	}

	.select-all {
		background-color: $color-gray-100;
		padding: rem(8) rem(16);
	}

	.list-select {
		min-height: rem(360);
		max-height: rem(360);
		list-style: none;
		padding: 0;
		margin: 0;

		.list-item {
			padding: rem(6) rem(16);

			label {
				margin-top: rem(3);
				cursor: pointer;
			}
		}
	}
</style>
