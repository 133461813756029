<template>
	<CRow class="mb-4 product-category-attaches">
		<CCol>
			<CRow class="mb-3">
				<CCol>
					<h6 data-test-id="title">
						{{ title }}
					</h6>
					<div
						class="typo-body-2 color-black-45"
						data-test-id="description"
					>
						{{ description }}
					</div>
				</CCol>
				<CCol
					lg="12 mt-3"
					class="text-right"
				>
					<div class="btn-action-wrapper">
						<CButton
							color="secondary"
							data-test-id="add-categories"
							@click="$refs['modal-category-selector'].open()"
						>
							Add Category Content
						</CButton>
						<CButton
							color="secondary"
							class="btn btn-secondary"
							@click="$refs['modal-sku'].open()"
						>
							Add Product Content
						</CButton>
					</div>
				</CCol>
			</CRow>
			<CRow class="mt-4">
				<CCol
					v-if="items.length"
					lg="12"
				>
					<CRow>
						<CCol>
							<span class="label">Content</span>
						</CCol>
					</CRow>
					<ul class="list-unstyled p-0">
						<draggable
							class="border rounded-sm border-secondary"
							data-test-id="product-group-sku"
							tag="li"
							:value="itemsWithTypeName"
							:disabled="!isDraggable"
							@input="handleUpdateOrder"
						>
							<li
								v-for="item in itemsWithTypeName"
								:key="item.sku"
								class="typo-body-2 d-flex text-decoration-none draggable-item"
								:class="{'draggable':isDraggable}"
							>
								<div class="category-content-item">
									<img class="image" :src="item.imageDesktop.imageUrls.thumbnail" alt="">
									<div class="detail">
										<div><b>title:</b> {{ item.titleEN }} <b>{{ (item.isHighlight === true ? '(Highlight item)' : '') }}</b></div>
										<div v-if="item.type === CATEGORY_CONTENT_TYPE.CATEGORY && item.categoryName && item.categoryName.name">
											<b> {{ typeTitleText(item.type) }}</b> {{ item.categoryName.name }}
										</div>
										<div v-if="item.type === CATEGORY_CONTENT_TYPE.PRODUCT && item.product && item.product.name">
											<b>{{ typeTitleText(item.type) }}</b> {{ item.product.name }}
										</div>
										<div><b>type:</b> {{ item.type }}</div>
										<div><b>date time:</b> {{ item.startAt }}{{ item.endAt ? ' - ' + item.endAt : '' }}</div>
										<!--//* hide text relate S7APP-1286-->
										<!-- <div><b>status:</b> {{ item.isActive === true ? 'active' : 'inactive' }} </div> -->
										<!-- <div><b>destination:</b> {{ item.destinationType }}</div> -->
									</div>
								</div>
								<!-- action wrapper -->
								<div class="align-self-start">
									<div class="mb-2 d-flex justify-content-end">
										<CBadge class="badge-status mr-2" :color="BANNER_STATUSES[item.isActive].value">
											{{ BANNER_STATUSES[item.isActive].name }}
										</CBadge>
										<CBadge v-if="item.period" class="badge-status" :color="BANNER_PERIOD_COLOR[item.period]">
											{{ BANNER_PERIOD_NAME[item.period] }}
										</CBadge>
									</div>

									<div class="action-box">
										<a
											href="#"
											class="btn-edit"
											data-test-id="edit"
											@click="editCategoryContent(item)"
										>
											<CIcon class="mr-2 cil-pencil" name="cil-pencil" />
										</a>
										<a
											href="#"
											class="btn-remove"
											data-test-id="remove"
											@click="removeCategoryContent(item)"
										>
											<CIcon class="mr-2 cil-trash" name="cil-trash" />
										</a>
									</div>
								</div>
							</li>
						</draggable>
					</ul>
				</CCol>
			</CRow>
		</CCol>
		<ModalSingleSKU
			ref="modal-sku"
			:product="productItem"
			title="Add products"
			@onConfirm="(data) => {createNewContent(data, CATEGORY_CONTENT_TYPE.PRODUCT);$refs['modal-sku'].handleClear()}"
			@onCancel="$refs['modal-sku'].handleClear()"
			@onClose="$refs['modal-sku'].handleClear()"
		/>
		<ModalCategorySingleSelect
			ref="modal-category-selector"
			title="Select categories"
			:selected-ids="categoryItem"
			:enable-select-all="false"
			@onSubmit="(data) => {createNewContent(data, CATEGORY_CONTENT_TYPE.CATEGORY);$refs['modal-category-selector'].handleClearAllChecked()}"
			@onCancel="$refs['modal-category-selector'].handleClearAllChecked()"
		/>
		<ModalCategoryContentForm
			ref="modal-lob-plp-content"
			:category-content.sync="localCurrentContent"
			:display-type="displayType"
			@onSubmit="submitCategoryContent"
			@onCancel="setDefaultLocalContent"
		/>
	</CRow>
</template>

<script>

import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import { v4 as uuidv4 } from 'uuid';
import ModalSingleSKU from '@/components/ModalSingleSKU.vue';
import ModalCategoryContentForm from '@/components/ModalCategoryContentForm.vue';
import ModalCategorySingleSelect from '@/components/ModalCategorySingleSelect.vue';
import { IMAGE_PLACEHOLDER_URL } from '../enums/general';
import { PRODUCT_FAMILY_ITEM_TYPES } from '../enums/productFamilies';
import { CATEGORY_CONTENT_TYPE, CATEGORY_CONTENT_DESTINATION_TYPE, CATEGORY_DISPLAY_TYPE, SHOW_LOWEST_PRICE_TYPE } from '../enums/categories';
import { getProductAPI } from '../services/api/products.api';
import { dateFormat } from '../assets/js/helpers';
import { BANNER_PERIOD_COLOR, BANNER_PERIOD_NAME, BANNER_STATUSES } from '../enums/banners';


export default {
	name: 'CategoryContentAttaches',
	components: {
		draggable,
		ModalSingleSKU,
		ModalCategorySingleSelect,
		ModalCategoryContentForm,
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		isDraggable: {
			type: Boolean,
			default: false,
		},
		displayType: {
			type: String,
			default: CATEGORY_DISPLAY_TYPE.SEARCH_LIST,
		},
	},
	data() {
		return {
			BANNER_STATUSES,
			BANNER_PERIOD_NAME,
			BANNER_PERIOD_COLOR,
			PRODUCT_FAMILY_ITEM_TYPES,
			CATEGORY_CONTENT_TYPE,
			IMAGE_PLACEHOLDER_URL,
			localCurrentContent: {},
			dummyList: [],
			productByIds: {},
		};
	},
	computed: {
		...mapGetters({
			getCategoryById: 'categorySelector/getCategoryById',
		}),
		productItem() {
			return this.localCurrentContent?.data?.[0] || null;
		},
		categoryItem() {
			return [this.localCurrentContent?.data?.[0]?.id] || [];
		},
		itemsWithTypeName() {
			return this.items.map(((val) => {
				//* map new data for display in content item
				val.startAt = val.startDate ? dateFormat(val.startDate, 'DD/MM/YYYY, HH:mm') : '-';
				val.endAt = val.endDate ? dateFormat(val.endDate, 'DD/MM/YYYY, HH:mm') : null;
				if (val.type === CATEGORY_CONTENT_TYPE.CATEGORY) {
					const categoryName = this.getCategoryById(val.typeId);
					return {
						...val,
						categoryName,
					};
				} else if (val.type === CATEGORY_CONTENT_TYPE.PRODUCT && this.productByIds[val.typeId]) {
					return {
						...val,
						product: this.productByIds[val.typeId],
					};
				}

				return val;
			}));
		},
	},
	watch: {
		items: {
			immediate: true,
			handler(list) {
				this.dummyList = list;
				list.forEach((value) => {
					if (value.type === CATEGORY_CONTENT_TYPE.PRODUCT) {
						if (!this.productByIds[value.typeId]) {
							this.fetchProductById(value.typeId);
						}
					}
				});
			},
		},
	},
	created() {
		this.setDefaultLocalContent();
	},
	methods: {
		typeTitleText(type) {
			return `${type} name:`;
		},
		fetchProductById(id) {
			getProductAPI(id).then(({ data }) => {
				this.productByIds = { ...this.productByIds, [id]: data?.data };
			});
		},
		createNewContent(data, type) {
			// if no data do nothing
			if (!data) {
				return;
			}
			this.localCurrentContent = {
				...this.localCurrentContent,
				id: uuidv4(),
				// Replace item type
				type,
				typeId: data?.[0]?.id || data?.id,
				data: data?.[0] || data || null,
				// Set default destination type by item type
				destinationType: type === CATEGORY_CONTENT_TYPE.PRODUCT ? CATEGORY_CONTENT_DESTINATION_TYPE.PDP : CATEGORY_CONTENT_DESTINATION_TYPE.PLP,
			};
			this.$refs['modal-lob-plp-content'].open(this.localCurrentContent.badgeLabels);
		},
		editCategoryContent(item) {
			this.localCurrentContent = item;
			this.$refs['modal-lob-plp-content'].open(this.localCurrentContent.badgeLabels);
		},
		removeCategoryContent({ id }) {
			this.$emit('update:items', this.dummyList.filter((item) => item.id !== id));
		},
		submitCategoryContent(data) {
			// Update case
			if (this.dummyList.find((item) => item.id === data.id)) {
				this.$emit('update:items', this.dummyList.map((item) => {
					if (item.id === data.id) {
						return data;
					}
					return item;
				}));
			} else { // create case
				this.$emit('update:items', [...this.dummyList, data]);
			}
			this.setDefaultLocalContent();
		},
		setDefaultLocalContent() {
			this.localCurrentContent = {
				titleEN: '',
				titleTH: '',
				subTitleEN: '',
				subTitleTH: '',
				displayLowestPriceType: SHOW_LOWEST_PRICE_TYPE.APPEND_DESCRIPTION,
				isLowestPriceVisible: false,
				descriptionEN: '',
				descriptionTH: '',
				shortDescriptionEN: '',
				shortDescriptionTH: '',
				destinationType: CATEGORY_CONTENT_DESTINATION_TYPE.PDP,
				searchKeyword: null,
				type: null, // product or category
				typeId: null,
				isHighlight: false, // true/false
				isActive: false, // true/false
				priority: 0, // priority for drag&drop
				imageDesktop: null,
				imageMobile: null,
				btnTitleEN: '',
				btnTitleTH: '',
				contentEN: '',
				contentTH: '',
				isContentHTMLActive: false,
				startDate: null,
				startTime: '00:00',
				endDate: null,
				endTime: '23:59',
				badgeLabels: [],
				customLink: '',
				buttonNameTh: '',
				buttonNameEn: '',
				isActiveButton: true,
			};
		},
		handleUpdateOrder(sortedList) {
			if (!this.isDraggable) {
				return;
			}
			this.dummyList = sortedList;

			this.$emit('update:items', this.dummyList);
		},
	},
};
</script>

<style lang="scss" scoped>
.product-category-attaches {
	.thumbnail {
		width: rem(40);
		height: rem(40);
		object-fit: contain;
	}

	.description {
		color: $color-black-45;
	}

	.badge-primary-product {
		background-color: $color-gray-400;
		color: $color-black-70;
	}

	.draggable {
		cursor: move;

		&:hover {
			background-color: $color-gray-100;
		}

		// .draggable-item
		&-item {
			display: flex;
			align-items: center;
			min-height: rem(80);
			padding: rem(8);

			> .row {
				margin-bottom: 0 !important;
			}
		}
	}

	.btn-action-wrapper {
		display: flex;
		gap: rem(8);
		margin-top: rem(16);

		@include lg {
			margin-top: 0;
		}
	}

	.category-content-item {
		flex: 1 1 0;
		display: flex;
		gap: rem(16);
		flex-flow: row nowrap;

		.detail {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;

			word-break: break-word;
		}

		.image {
			width: rem(64);
			height: rem(64);
		}
	}

	.action-box {
		display: flex;
		gap: rem(8);
		flex: 0 0 rem(60);
		align-items: center;
		justify-content: flex-end;

		.cil-trash, .cil-pencil {
			color: $color-gray-500;
		}
	}
}
</style>

