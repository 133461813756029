<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		centered
		class="modal-category"
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				:title="title"
				@onClose="handleCancel"
			/>
		</template>
		<template #default>
			<div class="px-2">
				<div class="d-flex align-items-center">
					<span class="pr-3">Status </span>
					<CSwitch
						id="category-banner-status"
						:checked="form.status"
						variant="3d"
						size="sm"
						class="switch-custom toggle-status"
						color="success"
						@update:checked="handleUpdateStatus"
					/>
				</div>
				<div class="mt-3">
					<h4 class="mb-4">
						{{ CATEGORY_REFS_TITLE[CATEGORY_REFS.CONTENT] }}
					</h4>
					<label>Category banners</label>
					<CategoryBanners
						ref="categoryBanners"
						v-model="form.banners"
						:is-show-delete-link="false"
						:is-show-add-banner-btn="false"
					/>
					<div class="my-4">
						<hr>
					</div>
					<div>
						<ActiveSchedule
							ref="date"
							title="Active Schedule"
							:detail="dateDetailText"
							:is-require="false"
							:start-date="form.startDate"
							:start-time="form.startTime"
							:end-date="form.endDate"
							:end-time="form.endTime"
							@update:startDate="form.startDate = $event"
							@update:startTime="form.startTime = $event"
							@update:endDate="form.endDate = $event"
							@update:endTime="form.endTime = $event"
						/>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<BaseModalFooter
				:disabled-confirm="isSubmitting"
				:disabled-cancel="isSubmitting"
				:disabled-clear="isSubmitting"
				@onCancel="handleCancel"
				@onConfirm="handleSubmit"
			/>
		</template>
	</CModal>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import ActiveSchedule from '@/components/ActiveSchedule.vue';
import CategoryBanners from '@/components/CategoryBanners.vue';
import { CATEGORY_REFS, CATEGORY_REFS_TITLE } from '../enums/categories';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		ActiveSchedule,
		CategoryBanners,
	},
	data() {
		return {
			CATEGORY_REFS,
			CATEGORY_REFS_TITLE,
			isShow: false,
			isSubmitting: false,
			dateDetailText: 'Category level.1 will be set as active and inactive follow to date and time below. Leave it blank to change label status manually.',
			isEdit: false,
			title: 'Create Banner',
			form: {
				status: false,
				startDate: null,
				startTime: '00:00',
				endDate: null,
				endTime: '23:59',
				banners: [
					{
						desktopFile: null,
						desktopENFile: null,
						mobileFile: null,
						mobileENFile: null,
						link: null,
					},
				],
			},
		};
	},
	methods: {
		initFormData(data = null) {
			if (!data) {
				this.title = 'Create Banner';
				this.isEdit = false;
				this.form = {
					status: false,
					startDate: null,
					startTime: '00:00',
					endDate: null,
					endTime: '23:59',
					banners: [
						{
							desktopFile: null,
							desktopENFile: null,
							mobileFile: null,
							mobileENFile: null,
							link: null,
						},
					],
				};
			} else {
				this.title = 'Edit Banner';
				this.isEdit = true;
				this.form.status = data.isActive;
				this.form.startDate = data.startDate ? new Date(data.startDate) : null;
				this.form.startTime = data.startTime;
				this.form.endDate = data.endDate ? new Date(data.endDate) : null;
				this.form.endTime = data.endTime;
				this.form.banners = [
					{
						desktopFile: data.desktopFile,
						desktopENFile: data.desktopENFile,
						mobileFile: data.mobileFile,
						mobileENFile: data.mobileENFile,
						link: data.link,
					},
				];
			}
		},
		open(editData = null) {
			this.isShow = true;
			this.initFormData(editData);
		},
		handleSubmit() {
			this.validateBanners();
			if (this.checkValidBanners()) {
				const formResponse = {
					isActive: this.form.status,
					startDate: convertDateTimeToUTC(this.form.startDate, this.form.startTime ?? '00:00'),
					startTime: this.form.startTime,
					endDate: convertDateTimeToUTC(this.form.endDate, this.form.endTime ?? '23:59'),
					endTime: this.form.endTime,
					desktopFile: this.form.banners[0].desktopFile,
					desktopENFile: this.form.banners[0].desktopENFile,
					mobileFile: this.form.banners[0].mobileFile,
					mobileENFile: this.form.banners[0].mobileENFile,
					link: this.form.banners[0].link,
				};
				this.$emit('onSubmit', {
					isEdit: this.isEdit,
					data: formResponse,
				});
				this.close();
			}
		},
		validateBanners() {
			const refs = this.$refs.categoryBanners.$refs.bannerInputMedia ?? [];
			refs.forEach((ref) => {
				ref.$v.$touch();
			});
		},
		checkValidBanners() {
			const refs = this.$refs.categoryBanners.$refs.bannerInputMedia ?? [];

			const isValid = refs
				.map((ref) => ref.$v.$invalid)
				.every((isInvalid) => isInvalid === false);

			return isValid;
		},
		close() {
			this.isShow = false;
		},
		handleUpdateStatus() {
			this.form.status = !this.form.status;
		},
		handleCancel() {
			this.close();
		},
	},
};
</script>

<style lang="scss" scoped>
.clear-button {
	cursor: pointer;
}

::v-deep .modal-dialog {
	max-width: 900px;
}
</style>
