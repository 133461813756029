<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="handleCancel"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol
						xl="12"
						class="d-flex align-items-center mb-3"
					>
						<label class="toggle-label mb-0 mr-2" for="lob-plp-display">
							Active Status
						</label>
						<CSwitch
							id="active"
							data-id="active-status"
							:checked="localCategoryContent.isActive"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="localCategoryContent.isActive = $event"
						/>
						<span class="ml-2">{{ categoryContentStatusText }}</span>
					</CCol>
					<CCol
						v-if="displayType === CATEGORY_DISPLAY_TYPE.LOB"
						xl="12"
						class="d-flex align-items-center mb-3"
					>
						<label class="toggle-label mb-0 mr-2" for="lob-plp-display">
							Highlight
						</label>
						<CSwitch
							id="highlight"
							data-id="highlight-status"
							:checked="localCategoryContent.isHighlight"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="localCategoryContent.isHighlight = $event"
						/>
						<span class="ml-2">{{ categoryContentHighlightText }}</span>
					</CCol>
					<CCol
						xl="12"
						class="d-flex align-items-center"
					>
						<BaseDropDown
							v-model="$v.localCategoryContent.destinationType.$model"
							class="w-100"
							:options="destinationTypeOptions"
							label="label"
							:allow-empty="false"
							:is-valid="!$v.localCategoryContent.destinationType.$error && null"
							:invalid-feedback="$t('global.error.required')"
							label-drop-down="Destination Type *"
							placeholder="Select destination type"
							@select="handleSelectedDestinationType"
						/>
					</CCol>
					<CCol v-if="localCategoryContent.destinationType && localCategoryContent.destinationType.value === CATEGORY_CONTENT_DESTINATION_TYPE.SEARCH_PAGE" md="12">
						<CInput
							v-model.trim="localCategoryContent.searchKeyword"
							description="search keyword will be used when destination type = search"
							label="Search Keyword"
						/>
					</CCol>
					<CCol v-if="localCategoryContent.destinationType && localCategoryContent.destinationType.value === CATEGORY_CONTENT_DESTINATION_TYPE.CUSTOM_LINK" md="12">
						<CInput
							v-model.trim="$v.localCategoryContent.customLink.$model"
							:is-valid="!$v.localCategoryContent.customLink.$error && null"
							:invalid-feedback="errorCustomLink"
							:description="'custom link for LOP,PLP example : https://www.studio7thailand.com/th'"
							label="Custom Link"
						/>
					</CCol>
					<CCol
						xl="12"
						class="d-flex align-items-center mb-3"
					>
						<label class="toggle-label mb-0 mr-2" for="lob-plp-display">
							Lowest Price Active Status
						</label>
						<CSwitch
							id="lowest-price-visible"
							data-id="lowest-price-visible"
							:checked="localCategoryContent.isLowestPriceVisible"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="localCategoryContent.isLowestPriceVisible = $event"
						/>
						<span class="ml-2">{{ categoryContentLowestPriceStatusText }}</span>
					</CCol>
					<CCol
						xl="12"
						class="d-flex align-items-center"
					>
						<BaseDropDown
							v-model="localCategoryContent.displayLowestPriceType"
							class="w-100"
							:options="SHOW_LOWEST_PRICE_TYPE_OPTIONS"
							label="label"
							label-drop-down="Display lowest price type"
							placeholder="Select display lowest price type"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="$v.localCategoryContent.titleEN.$model"
							:is-valid="!$v.localCategoryContent.titleEN.$error && null"
							data-id="category-content-title-en"
							label="Title (EN) *"
							:invalid-feedback="$t('global.error.required')"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="$v.localCategoryContent.titleTH.$model"
							:is-valid="!$v.localCategoryContent.titleTH.$error && null"
							data-id="category-content-title-th"
							label="Title (TH) *"
							:invalid-feedback="$t('global.error.required')"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="localCategoryContent.subTitleEN"
							data-id="category-content-subtitle-en"
							label="SubTitle (EN)"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="localCategoryContent.subTitleTH"
							data-id="category-content-subtitle-th"
							label="SubTitle (TH)"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="localCategoryContent.shortDescriptionEN"
							data-id="category-content-short-description-en"
							label="Short Description (EN)"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="localCategoryContent.shortDescriptionTH"
							data-id="category-content-short-description-th"
							label="Short Description (TH)"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="localCategoryContent.descriptionEN"
							data-id="category-content-description-en"
							label="Description (EN)"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="localCategoryContent.descriptionTH"
							data-id="category-content-description-th"
							label="Description (TH)"
						/>
					</CCol>
					<CCol md="12">
						<hr>
					</CCol>
					<template name="active-schedule">
						<CCol col="12" class="my-2">
							<ActiveSchedule
								ref="date"
								title="Active Schedule"
								:is-require="false"
								:start-date="localCategoryContent.startDate"
								:start-time="localCategoryContent.startTime"
								:end-date="localCategoryContent.endDate"
								:end-time="localCategoryContent.endTime"
								@update:startDate="localCategoryContent.startDate = $event"
								@update:startTime="localCategoryContent.startTime = $event"
								@update:endDate="localCategoryContent.endDate = $event"
								@update:endTime="localCategoryContent.endTime = $event"
							/>
							<hr>
						</CCol>
					</template>
					<CRow class="button-name px-3">
						<CCol col="12" class="mt-2">
							<h4>Button Name</h4>
							<div class="typo-body-2 color-black-45">Category level.1 will be set as active and inactive follow to date and time below. Leave it blank to change label status manually.</div>
						</CCol>
						<CCol
							col="12"
							class="d-flex align-items-center my-3"
						>
							<label class="mb-0 mr-3" for="lob-plp-display">
								Status
							</label>
							<CSwitch
								id="active"
								:checked="localCategoryContent.isActiveButton"
								variant="3d"
								size="sm"
								class="switch-custom toggle-status"
								color="success"
								@update:checked="handleIsActiveButton"
							/>
							<span class="ml-2">{{ BANNER_STATUSES[Boolean(localCategoryContent.isActiveButton)].name }}</span>
						</CCol>
						<template v-if="localCategoryContent.isActiveButton">
							<CCol col="6" class="pt-2">
								<CInput
									v-model.trim="localCategoryContent.buttonNameEn"
									placeholder="Button Name (EN)"
									label="Button Name (EN)"
								/>
							</CCol>
							<CCol col="6" class="pt-2">
								<CInput
									v-model.trim="localCategoryContent.buttonNameTh"
									placeholder="Button Name (TH)"
									label="Button Name (TH)"
								/>
							</CCol>
						</template>
					</CRow>
					<template name="badge-label">
						<CCol col="12" class="mt-2">
							<div class="border-top border-bottom py-4">
								<div class="d-flex justify-content-between w-100">
									<div>
										<h4>Badge Labels</h4>
									</div>
									<div>
										<CButton
											color="secondary"
											class="btn btn-secondary text-nowrap"
											@click="onClickShowAddForceBadgeLabelModal"
										>
											Add force badge label
										</CButton>
									</div>
								</div>
								<div class="py-3">
									<h6>Force badge Label</h6>
									<div class="typo-body-2 color-black-45">Force badge label will display on category LV1 on LOB Pages</div>
								</div>
								<div class="mt-2 px-2">
									<BadgeLabelList
										v-if="badgeLabelList.length"
										:list="badgeLabelList"
										is-disable-check
										is-show-icon-trash
										@remove="handleRemoveBadge"
									/>
								</div>
							</div>
						</CCol>
					</template>
					<CCol xl="12">
						<BannerContentWrapper
							ref="content"
							:has-banner-link="false"
						>
							<template #title>
								Content Image
							</template>
							<template #description>
								<p class="typo-body-2 color-black-45">Upload 2 sizes of banner one for desktop and another one for mobile.</p>
							</template>
							<template #detail>
								Content image will show in LOB/PLP Page
							</template>
							<CRow class="mt-4">
								<CCol xl="12">
									<BaseMediaSelectorBox
										ref="categoryContentProductImageDesktop"
										v-model="$v.localCategoryContent.imageDesktop.$model"
										title="Desktop"
										no-image-text="No Product Image"
										show-helper
										width="960"
										height="960"
										required
									/>
								</CCol>
								<CCol xl="12">
									<BaseMediaSelectorBox
										ref="categoryContentProductImageMobile"
										v-model="$v.localCategoryContent.imageMobile.$model"
										title="Mobile"
										no-image-text="No Product Image"
										show-helper
										width="480"
										height="480"
										required
									/>
								</CCol>
							</CRow>
						</BannerContentWrapper>
					</CCol>
				</CRow>
				<CRow>
					<CCol md="12">
						<h4>HTML Content</h4>
					</CCol>
					<CCol md="12" class="d-flex gap-3 mb-2">
						<label class="toggle-label" for="category-content-html">
							Status
						</label>
						<CSwitch
							id="category-content-html"
							data-id="category-content-html"
							:checked="localCategoryContent.isContentHTMLActive"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="(value) => localCategoryContent.isContentHTMLActive=value"
						/>
						<span>{{ statusText }}</span>
					</CCol>
					<CRow v-if="localCategoryContent.isContentHTMLActive" class="content-html-wrapper">
						<CCol md="6">
							<CInput
								v-model.trim="$v.localCategoryContent.btnTitleEN.$model"
								data-id="button-title-en"
								label="Button title (EN)*"
								:is-valid="!$v.localCategoryContent.btnTitleEN.$error && null"
								:invalid-feedback="$t('global.error.required')"
							/>
						</CCol>
						<CCol md="6">
							<CInput
								v-model.trim="$v.localCategoryContent.btnTitleTH.$model"
								data-id="button-title-th"
								label="Button title (TH)*"
								:is-valid="!$v.localCategoryContent.btnTitleTH.$error && null"
								:invalid-feedback="$t('global.error.required')"
							/>
						</CCol>

						<CCol md="12">
							<BaseRichTextEditor
								v-if="isShow"
								v-model="$v.localCategoryContent.contentEN.$model"
								name="Content (EN)*"
								:is-valid="!$v.localCategoryContent.contentEN.$error"
								:invalid-feedback="$t('global.error.required')"
							/>
						</CCol>
						<CCol md="12">
							<BaseRichTextEditor
								v-if="isShow"
								v-model="$v.localCategoryContent.contentTH.$model"
								name="Button title (TH)*"
								:is-valid="!$v.localCategoryContent.contentTH.$error"
								:invalid-feedback="$t('global.error.required')"
							/>
						</CCol>
					</CRow>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					@onCancel="handleCancel"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<ModalAddForceBadgeLabel
			ref="add-force-badge-label-modal"
			@onClose="handleOnCloseAddBadgeLabelModal"
			@onSubmit="handleSubmitAddBageLabelModal"
		/>
	</div>
</template>


<script>
import { required, requiredIf, url } from 'vuelidate/lib/validators';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import BannerContentWrapper from '@/components/BannerContentWrapper.vue';
import BaseMediaSelectorBox from '@/components/BaseMediaSelectorBox.vue';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import ModalAddForceBadgeLabel from '@/components/ModalAddForceBadgeLabel.vue';
import BadgeLabelList from '@/components/BadgeLabelList.vue';
import ActiveSchedule from '@/components/ActiveSchedule.vue';
import { convertDateTimeToUTC } from '../assets/js/helpers';
import {
	STATUS as mappingStatus,
	ENABLE as mappingEnable,
} from '../mapping/categories';
import {
	CATEGORY_DISPLAY_TYPE,
	SHOW_LOWEST_PRICE_TYPE_OPTIONS,
	CATEGORY_CONTENT_TYPE,
	CATEGORY_CONTENT_DESTINATION_TYPE,
	CATEGORY_CONTENT_PRODUCT_DESTINATION_OPTIONS,
	CATEGORY_CONTENT_CATEGORY_DESTINATION_OPTIONS,
} from '../enums/categories';
import { BANNER_STATUSES } from '../enums/banners';

export default {
	name: 'ModalCategoryContentForm',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		BannerContentWrapper,
		BaseMediaSelectorBox,
		ModalAddForceBadgeLabel,
		BadgeLabelList,
		ActiveSchedule,
	},
	props: {
		title: {
			type: String,
			default: 'Category Content',
		},
		categoryContent: {
			type: Object,
			default: () => {},
		},
		displayType: {
			type: String,
			default: CATEGORY_DISPLAY_TYPE.SEARCH_LIST,
		},
	},

	validations() {
		const defaultValidation = {
			localCategoryContent: {
				titleEN: {
					required,
				},
				titleTH: {
					required,
				},
				destinationType: {
					required,
				},
				imageDesktop: {
					required,
				},
				imageMobile: {
					required,
				},
				customLink: {
					required: requiredIf(() => this.localCategoryContent.destinationType && this.localCategoryContent.destinationType.value === CATEGORY_CONTENT_DESTINATION_TYPE.CUSTOM_LINK),
					url,
				},
			},
		};
		// if user active HTML Content it will validate 4 more field
		if (this.localCategoryContent.isContentHTMLActive) {
			return {
				localCategoryContent: {
					...defaultValidation.localCategoryContent,
					btnTitleEN: {
						required,
					},
					btnTitleTH: {
						required,
					},
					contentEN: {
						required,
					},
					contentTH: {
						required,
					},
				},
			};
		}

		return defaultValidation;
	},

	data() {
		return {
			isShow: false,
			CATEGORY_DISPLAY_TYPE,
			SHOW_LOWEST_PRICE_TYPE_OPTIONS,
			localCategoryContent: {},
			CATEGORY_CONTENT_DESTINATION_TYPE,
			CATEGORY_CONTENT_PRODUCT_DESTINATION_OPTIONS,
			CATEGORY_CONTENT_CATEGORY_DESTINATION_OPTIONS,
			BANNER_STATUSES,
			badgeLabelList: [],
		};
	},
	computed: {
		categoryContentStatusText() {
			const text = mappingStatus[this.localCategoryContent.isActive] || '';
			return this.$t(text);
		},
		categoryContentLowestPriceStatusText() {
			const text = mappingStatus[this.localCategoryContent.isLowestPriceVisible] || '';
			return this.$t(text);
		},
		categoryContentHighlightText() {
			const text = mappingEnable[this.localCategoryContent.isHighlight] || '';
			return text;
		},
		statusText() {
			const text = mappingStatus[this.localCategoryContent.isContentHTMLActive] || '';
			return this.$t(text);
		},
		destinationTypeOptions() {
			if (this.localCategoryContent.type === CATEGORY_CONTENT_TYPE.PRODUCT) {
				return CATEGORY_CONTENT_PRODUCT_DESTINATION_OPTIONS;
			}
			return CATEGORY_CONTENT_CATEGORY_DESTINATION_OPTIONS;
		},
		errorCustomLink() {
			if (!this.$v.localCategoryContent.customLink.$model) {
				return this.$t('global.error.required');
			}
			return this.$t('global.error.url');
		},
	},
	watch: {
		categoryContent: {
			immediate: true,
			handler(value) {
				this.localCategoryContent = {
					...value,
					destinationType: [...CATEGORY_CONTENT_PRODUCT_DESTINATION_OPTIONS, ...CATEGORY_CONTENT_CATEGORY_DESTINATION_OPTIONS].find((option) => option.value === value.destinationType),
					displayLowestPriceType: SHOW_LOWEST_PRICE_TYPE_OPTIONS.find((option) => option.value === value.displayLowestPriceType),
					startDate: value.startDate ? new Date(value.startDate) : null,
					endDate: value.endDate ? new Date(value.endDate) : null,
				};
			},
		},
	},
	methods: {
		resetState() {
			this.localCategoryContent = {};
			this.$v.$reset();
			this.$refs.categoryContentProductImageDesktop.$v.$reset();
			this.$refs.categoryContentProductImageMobile.$v.$reset();
		},
		open(badgeLabels = []) {
			this.isShow = true;
			this.badgeLabelList = badgeLabels;
		},
		close() {
			this.handleCancel();
		},
		handleCancel() {
			this.$emit('onCancel');
			this.resetState();
			this.isShow = false;
		},
		handleSubmit() {
			const dateRef = this.$refs.date.$v;
			dateRef.$touch();
			this.$v.$touch();
			this.$refs.categoryContentProductImageDesktop.$v.$touch();
			this.$refs.categoryContentProductImageMobile.$v.$touch();

			//* validate form input & validate active date input
			if (!this.$v.$invalid && !dateRef.$invalid) {
				this.$emit('onSubmit', {
					...this.localCategoryContent,
					destinationType: this.localCategoryContent.destinationType?.value || (this.localCategoryContent.type === CATEGORY_CONTENT_TYPE.PRODUCT ? CATEGORY_CONTENT_DESTINATION_TYPE.PDP : CATEGORY_CONTENT_DESTINATION_TYPE.PLP),
					displayLowestPriceType: this.localCategoryContent.displayLowestPriceType?.value || null,
					badgeLabels: this.badgeLabelList,
					startDate: this.localCategoryContent.startDate ? convertDateTimeToUTC(this.localCategoryContent.startDate, this.localCategoryContent.startTime ?? '00:00') : null,
					endDate: this.localCategoryContent.endDate ? convertDateTimeToUTC(this.localCategoryContent.endDate, this.localCategoryContent.endTime ?? '23:59') : null,
				});
				this.close();
			}
		},
		onClickShowAddForceBadgeLabelModal() {
			this.$refs['add-force-badge-label-modal'].open(this.localCategoryContent.id, this.badgeLabelList);
			this.isShow = false;
		},
		handleSubmitAddBageLabelModal(badgeList) {
			this.badgeLabelList = badgeList;
			this.open(this.badgeLabelList);
		},
		handleOnCloseAddBadgeLabelModal() {
			this.open(this.badgeLabelList);
		},
		handleRemoveBadge(data) {
			this.badgeLabelList = data;
		},
		handleSelectedDestinationType(selected) {
			if (selected.value !== CATEGORY_CONTENT_DESTINATION_TYPE.CUSTOM_LINK) {
				this.localCategoryContent.customLink = null; //* when destination_type != 'custom_link' and then reset custom link is empty
			}
		},
		handleLinkChange(link) {
			this.localCategoryContent.customLink = link;
		},
		handleIsActiveButton(status) {
			this.localCategoryContent.isActiveButton = status;
			if (!this.localCategoryContent.isActiveButton) {
				this.localCategoryContent.buttonNameEn = '';
				this.localCategoryContent.buttonNameTh = '';
			}
		},
		updateStartDateTime(data) {
			this.localCategoryContent.startAt = data;
		},
		updateEndDateTime(data) {
			this.localCategoryContent.endAt = data;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
	max-width: rem(700);
}

.no-item {
	min-height: rem(200);
	justify-content: center;
	align-items: center;
}

::v-deep .list-item {
	min-height: rem(200);
	padding: 0;
	margin: 0;
}

.toggle-label {
	flex: 0 0 rem(200);
}

.toggle-status {
	margin-right: rem(16);
}

.content-html-wrapper {
	margin-left: 0;
	margin-right: 0;
}
</style>
