<template>
	<div>
		<div class="d-flex justify-content-between">
			<h5>Category banners</h5>
			<CButton
				class="transparent"
				color="secondary"
				data-test-id="add-banner"
				@click="$refs['modal-category-banner'].open()"
			>
				<CIcon class="mr-2" name="cil-plus" /> Add Banner
			</CButton>
		</div>
		<CRow class="mt-4 category-banner-attaches">
			<CCol
				v-if="items.length"
				lg="12"
			>
				<ul class="list-unstyled p-0">
					<draggable
						class="border rounded-sm border-secondary"
						data-test-id="product-group-sku"
						tag="li"
						:value="items"
						:disabled="!isDraggable"
						@input="handleUpdateOrder"
					>
						<li
							v-for="(item, index) in items"
							:key="`banner_${index}`"
							class="typo-body-2 d-flex text-decoration-none draggable-item"
							:class="[
								{'draggable':isDraggable},
								index < items.length - 1 ? 'border-bottom' : ''
							]"
						>
							<div class="category-content-item">
								<img class="image" :src="item.desktopFile.urls.thumbnail" alt="desktop-thumbnail">
								<div class="detail">
									<div class="typo-body-1"> # {{ index+1 }} Banner</div>
									<div><b>date time:</b> {{ getFormatDate(item.startDate, 'startDate') }}{{ item.endDate ? ' - ' + getFormatDate(item.endDate) : '' }}</div>
								</div>
							</div>
							<!-- action wrapper -->
							<div class="align-self-start">
								<div class="mb-2 d-flex justify-content-end">
									<CBadge class="badge-status mr-2" :color="BANNER_STATUSES[item.isActive].value">
										{{ BANNER_STATUSES[item.isActive].name }}
									</CBadge>
									<CBadge v-if="item.period" class="badge-status" :color="BANNER_PERIOD_COLOR[item.period]">
										{{ BANNER_PERIOD_NAME[item.period] }}
									</CBadge>
								</div>

								<div class="action-box">
									<a
										href="#"
										class="btn-edit"
										data-test-id="edit"
										@click="handleEditContentBanner(index)"
									>
										<CIcon class="mr-2 cil-pencil" name="cil-pencil" />
									</a>
									<a
										href="#"
										class="btn-remove"
										data-test-id="remove"
										@click="handleRemoveContentBanner(index)"
									>
										<CIcon class="mr-2 cil-trash" name="cil-trash" />
									</a>
								</div>
							</div>
						</li>
					</draggable>
				</ul>
			</CCol>
		</CRow>
		<ModalCategoryBanner
			ref="modal-category-banner"
			@onSubmit="handleUpdateBanner"
		/>
	</div>
</template>
<script>
import ModalCategoryBanner from '@/components/ModalCategoryBanner.vue';
import draggable from 'vuedraggable';
import { dateFormat } from '../assets/js/helpers';
import { BANNER_PERIOD_COLOR, BANNER_PERIOD_NAME, BANNER_STATUSES } from '../enums/banners';

export default {
	components: {
		draggable,
		ModalCategoryBanner,
	},
	props: {
		banners: {
			type: Array,
			default: () => [],
		},
		isDraggable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			BANNER_PERIOD_COLOR,
			BANNER_PERIOD_NAME,
			BANNER_STATUSES,
			items: [],
		};
	},
	watch: {
		banners: {
			deep: true,
			immediate: true,
			handler(value) {
				this.items = value;
			},
		},
	},
	methods: {
		getFormatDate(date, key = '') {
			if (key === 'startDate') {
				return date ? dateFormat(date, 'DD/MM/YYYY , HH:mm') : '-';
			}
			return date ? dateFormat(date, 'DD/MM/YYYY , HH:mm') : null;
		},
		handleUpdateOrder(sortedList) {
			if (!this.isDraggable) {
				return;
			}
			this.items = sortedList;
			this.$emit('update:banners', this.items);
		},
		handleUpdateBanner(data) {
			if (data.isEdit) { //* case edit
				this.items[this.indexBanner] = data.data;
				this.items = [...this.items]; // set banner data in array
			} else { // * case create
				this.items.push(data.data);
			}
			this.$emit('update:banners', this.items);
		},
		handleEditContentBanner(index) {
			this.indexBanner = index;
			const data = this.items[index];
			this.$refs['modal-category-banner'].open(data);
		},
		handleRemoveContentBanner(index) {
			this.items.slice(index);
		},
	},
};
</script>
<style lang="scss" scoped>
.category-banner-attaches {
	.thumbnail {
		width: 40px;
		height: 40px;
		object-fit: contain;
	}

	.description {
		color: $color-black-45;
	}

	.badge-primary-product {
		background-color: $color-gray-400;
		color: $color-black-70;
	}

	.draggable {
		cursor: move;

		&:hover {
			background-color: $color-gray-100;
		}

		// .draggable-item
		&-item {
			display: flex;
			align-items: center;
			min-height: 80px;
			padding: 8px;

			> .row {
				margin-bottom: 0 !important;
			}
		}
	}

	.btn-action-wrapper {
		display: flex;
		gap: 8px;
		margin-top: 16px;

		@include lg {
			margin-top: 0;
		}
	}

	.category-content-item {
		flex: 1 1 0;
		display: flex;
		gap: 16px;
		flex-flow: row nowrap;

		.detail {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			word-break: break-word;

			.typo-body-1 {
				font-weight: $font-weight-bold;
			}
		}

		.image {
			width: 64px;
			height: 64px;
		}
	}

	.action-box {
		display: flex;
		gap: 8px;
		flex: 0 0 60px;
		align-items: center;
		justify-content: flex-end;

		.cil-trash, .cil-pencil {
			color: $color-gray-500;
		}
	}
}
</style>
