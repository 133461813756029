<template>
	<div>
		<div class="w-100 input-container">
			<label v-if="label" class="label">{{ label }}</label>

			<CInput
				v-if="isShowLinkInput"
				v-model.trim="$v.localLink.$model"
				:is-valid="!$v.localLink.$error && null"
				:invalid-feedback="$t('global.error.url')"
				:placeholder="placeholder"
				class="w-100 mb-0 input-link"
				type="text"
				@input="onChangeInput"
			/>

			<CButton
				v-if="isShowDeleteButton"
				class="btn-delete-item"
				@click="onClickDelete"
			>
				<CIcon name="cil-trash" />
			</CButton>
		</div>
	</div>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';

export default {
	name: 'BannerInputMedia',
	validations: {
		localLink: {
			url,
		},
		previewImage: {
			required,
		},
	},

	props: {
		label: {
			type: String,
			default: '',
		},
		link: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		isShowDeleteButton: {
			type: Boolean,
			default: true,
		},
		isShowLinkInput: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			localLink: null,
			previewImage: null,
		};
	},

	created() {
		this.localLink = this.link;
	},
	methods: {
		onChangeInput() {
			this.$emit('onChange', this.localLink);
		},
		onClickDelete() {
			this.$emit('onDelete');
		},
	},
};
</script>

<style lang="scss" scoped>
	.input-container {
		margin-right: rem(54);
		display: flex;
		align-items: baseline;
		gap: rem(16);
	}

	.btn-delete-item {
		color: $color-black-25;
		padding: 0;
	}
</style>
